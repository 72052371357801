<script lang="ts">
import { defineComponent } from 'vue'
import TopMenuItem from '@/components/TopMenuItem.vue'

export default defineComponent({
    name: 'MenuComponent',
    components: { TopMenuItem }
})
</script>

<template>
    <nav>
        <TopMenuItem itemText="Inicio" item-route="/"/>
        <TopMenuItem itemText="Portafolio" item-route="/portfolio"/>
        <TopMenuItem itemText="Herramientas" item-route="/optimize-image"/>
        <a href="https://codelabenterprise.com/content/" class="body-large topMenuItem">
            Blog
        </a>
        <TopMenuItem itemText="¿Quiénes somos?" item-route="/about"/>
    </nav>

</template>

<style scoped>

</style>
