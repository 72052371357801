<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'FooterComponent',
    computed: {
        ...mapState(['pathIsOptimizeTool', "isInFrame"])
    }
})
</script>

<template>
    <footer v-show="!isInFrame" class="title-medium" :class="{'dark-footer': pathIsOptimizeTool}">

        <div class="footer-menu">
            <section class="column">
                <h3>Compañía</h3>
                <ul>
                    <li>
                        <a href="#">Productos</a>
                    </li>
                    <li>
                        <a href="https://codelabenterprise.com/content/">Blog</a>
                    </li>
                    <li>
                        <a href="#">Contáctanos</a>
                    </li>
                </ul>
            </section>
            <section class="column">
                <h3>Más información</h3>
                <ul>
                    <li>
                        <router-link to="/terms-of-service">
                            Términos de servicio
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/privacy-policy">
                            Politica de privacidad
                        </router-link>
                    </li>
                </ul>
            </section>
        </div>
        <img v-if="!pathIsOptimizeTool" class="footer-logo" src="@/assets/logoCompleto.svg" alt="">
        <img v-else class="footer-logo" src="@/assets/logo-completo-dark.svg" alt="">

        <span class="copyrigth">
            © 2024 Codelabenterprise by Codelabenterprise todos los derechos reservados
        </span>
    </footer>
</template>

<style scoped>
.column {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
}

.footer-menu {
    display: flex;
    flex-direction: row;
    gap: 3rem
}

footer {
    padding: 20px;
    text-align: center;
    min-block-size: 160px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 80px;

    & a {
        color: var(--medium-gray);
    }

    & ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        justify-content: center;
        align-items: start;
    }

    & .footer-logo {
        block-size: 3.5rem;
        margin-inline: 2rem;
    }

    & .copyrigth {
        max-inline-size: 300px;
    }
}

.light-footer {
    background-color: var(--primary);
    color: var(--bone);
}

.dark-footer {
    background-color: transparent;
    color: var(--primary);

    & a {
        color: var(--primary);
        opacity: 0.6;
    }
}

@media (width <= 768px){
    footer {
        flex-direction: column;
        gap: 24px;
        padding-inline: 16px;
    }
}
</style>
