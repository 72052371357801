<script>
import {defineComponent} from 'vue'
import ActionButton from "@/components/ActionButton.vue";

export default defineComponent({
    name: "CookiesBanner",
    components: {ActionButton},
    methods: {
        getCookie(name) {
            const value = " " + document.cookie
            console.log("value", `==${value}==`)
            const parts = value.split(" " + name + "=")
            return parts.length < 2 ? undefined : parts.pop()?.split(";").shift()
        },
        setCookie(name, value, expiryDays, domain, path, secure) {
            const expirationDate = new Date()
            expirationDate.setHours(
                expirationDate.getHours() + (typeof expiryDays != "number" ? 365 : expiryDays) * 24
            )
            document.cookie =
                name +
                "=" +
                value +
                ";expires=" +
                expirationDate.toUTCString() +
                ";path=" +
                (path || "/") +
                (domain ? ";domain=" + domain : "") +
                (secure ? ";secure" : "")
        },
        hideBanner() {
            document.querySelector(".cookies-banner")?.remove()
        },
    },
    mounted() {
        if (this.getCookie("cookiesAccepted") === "true") {
            this.hideBanner()
        }
    }
});
</script>
<template>
    <aside class="cookies-banner">
        <span class="icon material-symbols-rounded">
            cookie
        </span>
        <div class="cookies-info">
            <p class="title-small">Este sitio web utiliza cookies para mejorar su experiencia. <br>Asumiremos que está
                de acuerdo con esto,<br>
                pero puede optar por no participar si lo desea.</p>
            <router-link class="label-large anchor-cookies" to="/cookies">Leer más</router-link>
        </div>
        <ActionButton text="Aceptar" @click="()=> {
            this.setCookie('cookiesAccepted', 'true')
            this.hideBanner()
        }"/>
    </aside>
</template>

<style scoped>

.cookies-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    inline-size: fit-content;
    margin: 1rem;

    position: fixed;
    z-index: 2;
    bottom: 0;
    right: 0;
    gap: 1rem;

    background-color: var(--secondary);
    color: var(--primary);
    border-radius: 0.25rem;

    padding: 1rem;
    align-items: center;
}

.cookies-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.anchor-cookies {
    color: #fff;
    text-decoration: underline;
}

.icon {
    background-color: rgba(var(--primary-rgb), 0.1);
    padding: 0.25rem;
    border-radius: 100%;
}
</style>
