<script>
import {defineComponent} from 'vue'
import ActionButton from '@/components/ActionButton.vue'
import {mapState} from "vuex";
import customBreakpoints from "@/utils/CustomBreakpoints"

const WEB3FORMS_ACCESS_KEY = "e9f97ff0-7fbc-484d-80ab-ef4df71ec7e0"

export default defineComponent({
    name: 'MainLanding',
    components: {
        ActionButton
    },
    inject: ['mq'],
    methods: {
        async sendEmail() {
            if (!this.email) {
                alert("Por favor, ingrese un correo válido")
                return
            }
            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    access_key: WEB3FORMS_ACCESS_KEY,
                    email: this.email,
                }),
            });
            const result = await response.json();
            if (result.success) {
                console.log(result);
                alert("Mensaje enviado")
            }
        }
    },
    computed: {
        isMobile() {
            return this.windowWidth <= customBreakpoints.mobileL
        },
        ...mapState(['windowWidth'])
    },
    data() {
        return {
            email: "",
        };
    }
})
</script>

<template>

    <section class="mainLanding">

        <section class="contact-section">
            <section class="mainTextContainer">
                <h1 class="mainText" :class="{'display-large': !isMobile, 'display-medium': isMobile}">
                    Soluciones innovadoras para la nueva <br><span class="keyword">era digital</span>
                </h1>
                <div class="description body-large">
                    Eleva tu negocio con nuestra tecnología:
                    software avanzado para líderes del mercado.
                    Transformamos tu visión en éxito.
                </div>
            </section>

            <form
                class="animate__animated animate__wobble animate__delay-1s" :class="{'input-wrapper-large': !isMobile, 'input-wrapper-small': isMobile}"
                @submit.prevent="sendEmail">
                <label for="email">
                    <input type="email" id="email" class="body-large" placeholder="Escribe tu dirección de correo"
                           v-model="email">
                </label>
                <ActionButton style="color: white" text="Contáctanos" class="contact-gradient"/>
            </form>

        </section>
    </section>

</template>

<style scoped>

.mainLanding {
    display: flex;
    flex-direction: row;
    justify-content: start;
    background-color: var(--primary);
    min-block-size: 95vh;
    overflow: hidden;
    background-image: url('@/assets/hero.webp');
    background-size: cover;

    & .contact-section {
        padding-inline: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-inline-size: 35%;
        gap: 48px;

        & .mainTextContainer {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-block: 20px;

            & .mainText {
                margin: 0;
            }

            & .description {
                opacity: 0.60;
            }
        }

        & .input-wrapper-large {
            display: flex;
            flex-direction: row;
            border: 1px solid darkgray;
            border-radius: 100px;
            align-items: center;
            padding-inline-start: 20px;
            justify-content: space-between;
            min-inline-size: 410px;

            & label {
                line-height: 1;
                flex-grow: 1;
                max-inline-size: 100%;
            }

            & input {
                min-inline-size: 100%;
            }
        }

        .input-wrapper-small {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            min-inline-size: fit-content;
            gap: 1rem;

            & label {
                border-radius: 10rem;
                border: 1px solid darkgray;
                padding: 0.5rem;
                line-height: 1;
                flex-grow: 1;
                inline-size: 100%;
                min-inline-size: fit-content;
            }

            & input {
                inline-size: 100%;
                min-inline-size: fit-content;
                block-size: 2rem;
                margin-inline: 1rem;
            }

            & .contact-gradient {
                min-inline-size: 100%;

            }
        }
    }

    & .img-landing {
        block-size: 800px;
        inline-size: 800px;
        z-index: 0;
        aspect-ratio: 1/1;
        align-self: end;
        mask-image: linear-gradient(
            to left,
            transparent,
            black 15%,
            black 80%,
            transparent
        );
    }
}

@media (width <= 1545px) {
    .mainLanding {
        & .contact-section {
            max-inline-size: 40%;
        }
    }
}

@media (width <= 1303px) {
    .mainLanding {
        background-position: center;

        & .contact-section {
            max-inline-size: 50%;
        }
    }
}

@media (width <= 1042px) {
    .mainLanding {
        & .contact-section {
            max-inline-size: 60%;
        }
    }
}

@media (width <= 768px) {
    .mainLanding {
        background-image: none;

        & .contact-section {
            max-inline-size: 100%;
            padding: 32px;
            justify-content: flex-start;

        }
    }
}
</style>
