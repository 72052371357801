<script>
import { defineComponent } from 'vue'
import {useRoute} from 'vue-router'
import logo from '@/assets/logoCompleto.svg'
import logoDark from '@/assets/logo-completo-dark.svg'

export default defineComponent({
    name: 'LogoNameBrand',
    computed: {
        logo() {
            return (this.$route.path === '/optimize-image' || this.$route.path === '/optimize-image/optimize') ? logoDark : logo
        }
    }
})
</script>

<template>
    <router-link to="/" class="brandContainer">
        <img id="logo" alt="logo" class="logo" :src="logo">
    </router-link>
</template>

<style scoped>
    .brandContainer {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;

        & .logo {
            image-rendering: auto;
            block-size: 3.5rem;
        }
    }
</style>
