<script>
import {defineComponent} from 'vue'
import InputTextField from '@/components/InputTextField.vue'
import {mapState} from "vuex";
import customBreakpoints from "@/utils/CustomBreakpoints";

const WEB3FORMS_ACCESS_KEY = "e9f97ff0-7fbc-484d-80ab-ef4df71ec7e0";

export default defineComponent({
    name: 'ContactView',
    components: {
        InputTextField
    },
    computed: {
        ...mapState(['windowWidth']),
        customBreakpoints() {
            return customBreakpoints
        },
        isMobile() {
            return this.windowWidth <= customBreakpoints.mobileL
        },
    },
    methods: {
        async sendEmail() {
            const fields = [
                {value: this.email, message: "Por favor, ingrese un correo válido", name: "email"},
                {value: this.name_user, message: "Por favor, ingrese su nombre", name: "name_user"},
                {value: this.lastname, message: "Por favor, ingrese su apellido", name: "lastname"},
                {value: this.phone, message: "Por favor, ingrese su teléfono", name: "phone"},
                {value: this.message, message: "Por favor, ingrese un mensaje", name: "message"}
            ];

            for (let field of fields) {
                if (!field.value) {
                    alert(field.message);
                    return;
                }
            }

            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    access_key: WEB3FORMS_ACCESS_KEY,
                    name: this.name_user,
                    lastname: this.lastname,
                    email: this.email,
                    message: this.message,
                    phone: this.phone,
                }),
            });
            const result = await response.json();
            if (result.success) {
                console.log(result);
                alert("Mensaje enviado")
            }
        }
    },
    data() {
        return {
            name_user: "",
            lastname: "",
            email: "",
            message: "",
            phone: "",
        };
    },
})
</script>

<template>
    <section class="contact-section" id="contact-view">
        <section class="contact">
            <header class="contact-header">
                <span :class="{
                    'display-large': !isMobile,
                    'display-medium': isMobile,
                    'display-small': windowWidth <= customBreakpoints.mobileS
                }">Contáctanos</span>
                <p class="subtitle headline-large">Hablemos sobre tu proyecto</p>
            </header>

            <form class="contact-form" @submit.prevent="sendEmail">
                <section class="names">
                    <InputTextField class="fill-max-width" id="name" name="name" label="Nombre:" v-model="name_user"/>
                    <InputTextField class="fill-max-width" id="lastname" name="lastname" label="Apellido:"
                                    v-model="lastname"/>
                </section>

                <section class="contact-fields">
                    <InputTextField id="email-contact" name="email-contact" label="Correo:" type="email"
                                    v-model="email"/>
                    <InputTextField id="phone" name="phone" label="Teléfono:" type="tel" v-model="phone"/>
                </section>

                <label class="title-medium" for="message">Mensaje:</label>
                <textarea class="body-large message-field" id="message" name="message" v-model="message"></textarea>
                <button class="body-large" type="submit">Enviar</button>
            </form>
        </section>
    </section>
</template>

<style scoped>

.contact-section {
    display: flex;
    padding: 80px 160px;
    justify-content: start;
    background-image: url("@/assets/contactform.webp");
    background-size: cover;
    background-position: center;

    & .contact-header {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    & .headline-large {
        opacity: 0.5;
    }

    & .contact {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-block: 80px;
    }

    & .contact-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    & .names {
        display: flex;
        flex-direction: row;
        gap: 20px;
        inline-size: 100%;
    }

    & .contact-fields {
        gap: 20px;
        display: flex;
        flex-direction: column;
    }

    & .message-field {
        resize: none;
        min-inline-size: 428px;
        padding: 16px;
        min-block-size: 80px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }

    & button {
        background: var(--secondary, #05204a);
        border-radius: 66px;
        border: none;
        padding: 12px 20px;
        color: #ffffff;
    }
}

.fill-max-width {
    inline-size: 100%;
}

@media (width <= 768px) {
    .contact-section {
        padding: 32px;
        gap: 40px;
        background-image: none;

        & .contact {
            padding: 16px;
            gap: 16px;
            overflow: hidden;
        }

        & .names {
            flex-direction: column;
        }

        & .message-field {
            min-inline-size: 0;
        }
    }

}
</style>
