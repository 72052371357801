import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7edb50a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-wrapper" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["type", "id", "name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "title-medium",
      for: _ctx.name
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    _createElementVNode("input", {
      class: "body-large",
      type: _ctx.type,
      id: _ctx.id,
      name: _ctx.name,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args)))
    }, null, 40, _hoisted_3)
  ]))
}