<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: "AboutView"
})
</script>

<template>
    <img src="@/assets/fondo-mision-vision.webp" alt="">
    <main>

        <div class="main-text">
            <span class="headline-small first">Dedicamos nuestros esfuerzos</span>
            <span class="headline-small second">desarrollando soluciones de software</span>
            <span class="headline-large third">enfocado en las PYMES</span>
        </div>



        <div class="content-wrapper">
            <div class="divider"></div>
            <details open>
                <summary class="display-medium">Mision</summary>

                <p class="body-large">
                    Ofreciendo precios justos y accesibles para todos. Nuestra misión es facilitar el crecimiento y éxito de
                    las pequeñas y medianas empresas a través de tecnología innovadora y personalizada. Nos comprometemos a
                    mantener un enfoque humano y sensible en todas nuestras interacciones, asegurando que nuestros clientes
                    reciban el apoyo y la atención que merecen. Creemos en la equidad y trabajamos para que las empresas,
                    independientemente de su tamaño o recursos, puedan acceder a herramientas tecnológicas de primera
                    calidad.</p>
            </details>
        </div>

        <div class="content-wrapper">
            <div class="divider"></div>
            <details open>
                <summary class="display-medium">Vision</summary>
                <p class="body-large">Ser la opción preferida de las pequeñas y medianas empresas (PYMES) a nivel global, proporcionando
                    software accesible y de alta calidad que les permita crecer y prosperar. Queremos ser reconocidos por
                    nuestra sensibilidad humana y nuestro compromiso con la equidad, ayudando a quienes más lo necesitan a
                    alcanzar su máximo potencial.</p>
            </details>
        </div>

    </main>
</template>

<style scoped>
img {
    position: fixed;
    z-index: -1;
    inline-size: 1000px;
    right: 0;
    top: 15dvh;
}

.divider {
    border-bottom: 2px solid var(--secondary);
    inline-size: clamp(2%, 4%, 5%);
    block-size: 2px;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    inline-size: clamp(50%, 50vw, 60%);
}

main {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding-inline: clamp(2rem, 5vw, 5rem);
    padding-block: clamp(2rem, 5vw, 6rem);
}

span {
    inline-size: fit-content;
}

.main-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    inline-size: fit-content;

    & .first {
        color: steelblue;
        font-weight: 600;
    }

    & .second {
        color: gray;
        font-weight: 600;
    }

    & .third {
        color: var(--secondary);

    }
}


summary {
    color: var(--secondary);
    padding-block-end: 0.5rem;
}

summary:hover {
    cursor: pointer;
    color: var(--secondary);
}


@media (width <= 1300px) {
    img {
        display: none;
    }
}

@media (width < 768px) {

    .content-wrapper {
        inline-size: clamp(80%, 80vw, 90%);
    }
}
</style>
