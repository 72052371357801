<template>
    <TopBar />
    <RouterView/>
    <CookiesBanner />
    <FooterComponent />
</template>

<script>
import TopBar from '@/components/TopBar.vue'
import FooterComponent from '@/components/Footer.vue'
import { mapMutations, mapState } from 'vuex'
import CookiesBanner from "@/cookies/CookiesBanner.vue";

export default {
    name: 'App',
    data() {
        return {
            app : null
        }
    },
    components: {
        CookiesBanner,
        TopBar,
        FooterComponent
    },
    watch: {
        $route(route) {
            if (route.path === '/optimize-image' || route.path === '/optimize-image/optimize' || this.isInFrame) {
                this.changePathIsOptimizeTool(true)
                this.app.classList.add('optimize-image-tool-class')
            } else {
                this.changePathIsOptimizeTool(false)
                this.app.classList.remove('optimize-image-tool-class')
            }

            if (route.path === '/') {
                this.changeRouteIsHome(true)
            } else {
                this.changeRouteIsHome(false)
            }
        },

    },
    mounted() {
        this.app = this.$el.parentElement;
        if(window.self !== window.top) {
            this.changeIsInFrame(true)
        }
        window.addEventListener('resize', this.updateWindowWidth)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth)
    },
    computed: {
        ...mapState(["isInFrame"])
    },
    methods: {
        ...mapMutations([
            'changePathIsOptimizeTool',
            'changeRouteIsHome',
            "changeIsInFrame",
            "changeWindowWidth"
        ]),
        updateWindowWidth() {
            this.changeWindowWidth(window.innerWidth)
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,200..800;1,200..800&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poetsen+One&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap&family=Inter:wght@100..900');
@import '@/assets/css/font-type.css';
@import '@/assets/css/gradient-anim.css';
@import '@/assets/css/scrollbar.css';

.optimize-image-tool-class {
    background-image: url("@/assets/fondo-optimize-tool.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

@media (width  <= 950px) {
    .optimize-image-tool-class {
        background-image: url("@/assets/fondo-optimize-tool-vertical.webp");
    }
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    height: inherit;
    width: inherit;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    margin: 0;
}

.keyword {
    background: linear-gradient(76.72deg, #05204a 0%, #ff005c 0%, #f46036 94.5%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-gradient {
    align-self: flex-end;
    background: linear-gradient(76.72deg, #05204a 0%, #ff005c 0%, #f46036 94.5%);
}

& .contact-gradient:hover {
    background-size: 200% 200%;

    -webkit-animation: CustomAnimation 3s ease infinite;
    -moz-animation: CustomAnimation 3s ease infinite;
    -o-animation: CustomAnimation 3s ease infinite;
    animation: CustomAnimation 3s ease infinite;
}

& input {
    outline: none;
    border: none;
    background-color: transparent;
}

li, ul, ol {
    list-style: none;
    margin: 0;
}

a {
    text-decoration: none;
}

.topMenuItem {
    color: black;
}

nav li:hover,
nav .router-link-active,
nav .router-link-exact-active {
    background-color: rgba(var(--accent-rgb), 1);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    color: var(--primary);
}

:root {
    /* Colors */
    --primary: #ffffff;
    --primary-rgb: 255, 255, 255;
    --secondary: #05204a;
    --secondary-rgb: 5, 32, 74;
    --accent: #f46036;
    --accent-rgb: 244, 96, 54;
    --bone: #f2f2f2;
    --bone-rgb: 242, 242, 242;
    --medium-gray: #666666;

    /* Fonts */
    --display-large-font-family: Poetsen One, sans-serif;
    --min-display-large-font-size: 2.96rem;  /* 57px / 16 */
    --max-display-large-font-size: 4rem;      /* 64px / 16 */
    --display-large-line-height: 4rem;        /* 64px / 16 */
    --display-large-font-weight: 400;
    --display-large-font-style: normal;

    --display-medium-font-family: Poetsen One, sans-serif;
    --display-medium-font-size: 2.8125rem;    /* 45px / 16 */
    --display-medium-line-height: 3.25rem;    /* 52px / 16 */
    --display-medium-font-weight: 400;
    --display-medium-font-style: normal;

    --display-small-font-family: Poetsen One, sans-serif;
    --display-small-font-size: 2.25rem;       /* 36px / 16 */
    --display-small-line-height: 2.75rem;     /* 44px / 16 */
    --display-small-font-weight: 400;
    --display-small-font-style: normal;

    --headline-large-font-family: Nunito, sans-serif;
    --headline-large-font-size: 2rem;         /* 32px / 16 */
    --headline-large-line-height: 2.5rem;     /* 40px / 16 */
    --headline-large-font-weight: 800;
    --headline-large-font-style: normal;

    --headline-medium-font-family: Nunito, sans-serif;
    --headline-medium-font-size: 1.75rem;     /* 28px / 16 */
    --headline-medium-line-height: 2.25rem;   /* 36px / 16 */
    --headline-medium-font-weight: 800;
    --headline-medium-font-style: normal;

    --headline-small-font-family: Nunito, sans-serif;
    --headline-small-font-size: 1.5rem;       /* 24px / 16 */
    --headline-small-line-height: 2rem;       /* 32px / 16 */
    --headline-small-font-weight: 800;
    --headline-small-font-style: normal;

    --title-large-font-family: Inter, sans-serif;
    --title-large-font-size: 1.375rem;        /* 22px / 16 */
    --title-large-line-height: 1.75rem;       /* 28px / 16 */
    --title-large-font-weight: 400;
    --title-large-font-style: normal;

    --title-medium-font-family: Inter, sans-serif;
    --title-medium-font-size: 1rem;           /* 16px / 16 */
    --title-medium-line-height: 1.5rem;       /* 24px / 16 */
    --title-medium-font-weight: 500;
    --title-medium-font-style: normal;

    --title-small-font-family: Inter, sans-serif;
    --title-small-font-size: 0.875rem;        /* 14px / 16 */
    --title-small-line-height: 1.25rem;       /* 20px / 16 */
    --title-small-font-weight: 500;
    --title-small-font-style: normal;

    --label-large-font-family: Inter, sans-serif;
    --label-large-font-size: 0.875rem;        /* 14px / 16 */
    --label-large-line-height: 1.25rem;       /* 20px / 16 */
    --label-large-font-weight: 500;
    --label-large-font-style: normal;

    --label-medium-font-family: Roboto, sans-serif;
    --label-medium-font-size: 0.75rem;        /* 12px / 16 */
    --label-medium-line-height: 1rem;         /* 16px / 16 */
    --label-medium-font-weight: 500;
    --label-medium-font-style: normal;

    --label-small-font-family: Roboto, sans-serif;
    --label-small-font-size: 0.6875rem;       /* 11px / 16 */
    --label-small-line-height: 1rem;          /* 16px / 16 */
    --label-small-font-weight: 500;
    --label-small-font-style: normal;

    --body-large-font-family: Roboto, sans-serif;
    --body-large-font-size: 1rem;             /* 16px / 16 */
    --body-large-line-height: 1.5rem;         /* 24px / 16 */
    --body-large-font-weight: 400;
    --body-large-font-style: normal;

    --body-medium-font-family: Roboto, sans-serif;
    --body-medium-font-size: 0.875rem;        /* 14px / 16 */
    --body-medium-line-height: 1.25rem;       /* 20px / 16 */
    --body-medium-font-weight: 400;
    --body-medium-font-style: normal;

    --body-small-font-family: Roboto, sans-serif;
    --body-small-font-size: 0.75rem;          /* 12px / 16 */
    --body-small-line-height: 1rem;           /* 16px / 16 */
    --body-small-font-weight: 400;
    --body-small-font-style: normal;

    /* Effects */
}

.material-symbols-rounded {
    font-variation-settings: 'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

summary  {
    list-style: none;
}

/* sigh, Safari again */

summary::-webkit-details-marker {
    display: none;
}

iframe {
    border: none;
    border-radius: 1rem;
}
</style>
