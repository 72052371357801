const breakpoints = {
        mobileS: 320,
        mobileM: 375,
        mobileL: 450,
        tablet: 768,
        laptop: 1024,
        laptopL: 1440,
        desktop: Infinity,
}

export default breakpoints;
