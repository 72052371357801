/* eslint-disable */
import { createStore } from 'vuex'

export default createStore({
    state: {
        pathIsOptimizeTool: false,
        isRouteHome: true,
        isInFrame: false,
        windowWidth: window.innerWidth
    },
    getters: {
        getPathIsOptimizeTool(state) {
            return state.pathIsOptimizeTool
        }
    },
    mutations: {
        changePathIsOptimizeTool(state, value) {
            state.pathIsOptimizeTool = value
        },
        changeRouteIsHome(state, value) {
            state.isRouteHome = value
        },
        changeIsInFrame(state, value) {
            state.isInFrame = value
        },
        changeWindowWidth(state, value) {
            state.windowWidth = value
        }
    },
    actions: {
    },
    modules: {
    }
})
