import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a853b274"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainLanding = _resolveComponent("MainLanding")!
  const _component_PortfolioList = _resolveComponent("PortfolioList")!
  const _component_ContactView = _resolveComponent("ContactView")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_MainLanding),
    _createVNode(_component_PortfolioList),
    _createVNode(_component_ContactView)
  ]))
}