<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ActionButton',
    props: {
        text: {
            type: String,
            required: true
        }
    }
})
</script>

<template>
    <button class="contact-btn body-large">{{ text }}</button>
</template>

<style scoped>
.contact-btn {
    border-radius: 66px;
    border: none;
    padding: 12px 20px;
    font-style: italic;
    cursor: pointer;
}

.contact-btn:hover {
    transform: scale(1.1);
}

</style>
