import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e91e689e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "featuresItem" }
const _hoisted_2 = { class: "icon material-symbols-rounded" }
const _hoisted_3 = { class: "features-text headline-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.imgSrc), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ]))
}