<script lang="ts">
import { defineComponent } from 'vue'
import MainLanding from '@/views/MainLanding.vue'
import PortfolioList from '@/views/PortfolioList.vue'
import ContactView from '@/views/ContactView.vue'

export default defineComponent({
    name: 'HomeView',
    components: {
        MainLanding,
        PortfolioList,
        ContactView,
    }
})
</script>

<template>
    <main class="main-home">
        <MainLanding />
        <PortfolioList/>
        <ContactView />
    </main>
</template>

<style scoped>
.main-home {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}


</style>
