<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'InputTextField',
    props: {
        label: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    methods: {
        updateValue(event: any) {
            this.$emit('update:modelValue', event.target.value);
        }
    },
})
</script>

<template>
    <div class="field-wrapper">
        <label class="title-medium" :for="name">{{ label }}</label>
        <input class="body-large" :type="type" :id="id" :name="name" :value="modelValue" @input="updateValue">
    </div>
</template>

<style scoped>
.field-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;

    & input {
        padding: 8px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
}
</style>
