<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TopMenuItem',
    props: {
        itemText: {
            type: String,
            required: true
        },
        itemRoute: {
            type: String,
            required: true
        }
    }
})
</script>

<template>
    <router-link :to="itemRoute" exact class="topMenuItem body-large">
        {{ itemText }}
    </router-link>
</template>

<style>
.topMenuItem:hover {
    transform: scale(1.1);
}
</style>
