<script lang="ts">
import { defineComponent } from 'vue'
import ActionButton from '@/components/ActionButton.vue'
import LogoNameBrand from '@/components/LogoNameBrand.vue'
import MenuComponent from '@/components/Menu.vue'
import { mapState } from 'vuex'

export default defineComponent({
    name: 'TopBar',
    components: {
        ActionButton,
        LogoNameBrand,
        MenuComponent
    },
    data () {
        return {
            hasScrolled: false
        }
    },
    computed: {
        ...mapState(['pathIsOptimizeTool', 'isRouteHome', "isInFrame"])
    },
    watch: {
        $route() {
            const checkbox = document.getElementById('check') as HTMLInputElement
            checkbox.checked = false
        },

    },
    methods: {
        handleScroll() {
            this.hasScrolled = window.scrollY >= 80; // Change 100 to the scroll threshold you prefer
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
})

</script>

<template>
    <header v-show="!isInFrame" class="topBar" :class="{'light-topBar': !pathIsOptimizeTool, 'dark-topBar': pathIsOptimizeTool, 'scroll': hasScrolled && pathIsOptimizeTool}">
        <input type="checkbox" id="check"/>
        <LogoNameBrand/>
        <MenuComponent class="menu" :class="{'dark-menu': pathIsOptimizeTool}"/>

        <a href="#contact-view" v-if="isRouteHome">
            <ActionButton :class="{'dark-button': !pathIsOptimizeTool, 'light-button': pathIsOptimizeTool}" text="Contáctanos"/>
        </a>
        <router-link v-else to="/">
            <ActionButton :class="{'dark-button': !pathIsOptimizeTool, 'light-button': pathIsOptimizeTool}" text="Contáctanos"/>
        </router-link>
        <label for="check" class="menu-icon">
            <span class="material-symbols-rounded">
                menu
            </span>
        </label>
    </header>

    <div class="spacer" v-if="!isInFrame"/>
</template>

<style>

.dark-button {
    background-color: var(--secondary);
    color: white;
}

.light-button {
    background-color: var(--primary);
    color: black;
}

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 clamp(2rem, 5vw, 5rem);
    min-block-size: 5rem;
    overflow: hidden;
    position: fixed;
    z-index: 3;
    inline-size: 100%;

    & .menu-icon {
        font-size: 24px;
        cursor: pointer;
        display: none;
    }
}

.dark-topBar {
    background-color: transparent;
    transition: all 0.25s ease;

    & .menu-icon {
        color: var(--primary);
    }
}

.dark-topBar.scroll {
    background-color: var(--secondary);
}

.light-topBar {
    background-color: var(--primary);

    & .menu-icon {
        color: var(--secondary);
    }
}


.spacer {
    block-size: 5rem;
}

#check {
    display: none;
}


.menu {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
}

.dark-menu {
    & a {
        color: var(--primary);
    }
}


@media (width <= 950px) {
    .menu {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 70px;
        right: -100%;
        transition: all 0.5s;
        background-color: var(--primary);
        padding-block-start: 1rem;
        margin-block-start: 0.5rem;
        height: 100%;
        width: 100%;
        gap: 40px;
    }

    .dark-menu {
        background-color: var(--secondary);
    }

    #check:checked ~ .menu {
        right: 0;
    }

    .topBar {
        & .contact-btn {
            display: none;
        }

        & .menu-icon {
            display: block;
        }
    }
}
</style>
