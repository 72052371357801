import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PortfolioList from '@/views/PortfolioList.vue'
import AboutView from '@/views/AboutView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView

    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: import('@/views/PrivacyPolicy.vue')
    },
    {
        path: '/terms-of-service',
        name: 'terms-of-service',
        component: () => import('@/views/TermsOfService.vue')
    },
    {
        path: '/cookies',
        name: 'cookies',
        component: () => import('@/views/CookiesNotice.vue')
    },
    {
        path: '/optimize-image',
        name: 'optimize-image',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        children: [
            {
                path: '',
                component: () => import('@/CompressImageTool/views/HomeCompressImage.vue')
            },
            {
                path: 'optimize',
                component: () => import('@/CompressImageTool/views/CompressImageView.vue')
            }
        ]
    },
    {
        path: '/portfolio',
        name: 'portfolio',
        component: PortfolioList
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
})

export default router
