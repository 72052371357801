<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FeaturesItem',
    props: {
        imgSrc: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        }
    }
})
</script>

<template>
    <section class="featuresItem">
        <span class="icon material-symbols-rounded">
            {{ imgSrc }}
        </span>
        <span class="features-text headline-small">{{ text }}</span>
    </section>
</template>

<style scoped>
.featuresItem {
    display: flex;
    flex-direction: column;
    gap: 16px;
    inline-size: min-content;
    min-inline-size: 200px;
    border-radius: 20px;
    background-color: rgba(var(--primary-rgb), 0.25);
    padding-inline: 32px;
    padding-block: 24px;

    & .icon {
        color: white;
    }

    & .features-text {
        color: white;
        inline-size: 100%;
    }
}
</style>
