<script lang="ts">
import { defineComponent } from 'vue'
import FeaturesItem from '@/components/FeaturesItem.vue'

export default defineComponent({
    name: 'PortfolioList',
    components: {
        FeaturesItem
    }
})
</script>

<template>
    <section class="portfolioSection">
        <header class="portfolioHeader">
            <span class="headline-medium">Descubre el <span class="keyword">impacto</span> de nuestras <br>soluciones tecnológicas</span>
            <section class="main-features-list">
                <FeaturesItem text="Soluciones a tu medida" img-src="emoji_objects"/>
                <FeaturesItem text="Inovación en cada paso" img-src="data_exploration"/>
                <FeaturesItem text="Velocidad y  eficiencia juntas" img-src="speed"/>
            </section>
        </header>
        <section class="portfolioList">
            <article class="portfolioItem">
                <iframe class="portfolioItem" width="100%" height="100%" src="https://codelabenterprise.com/content/"></iframe>
            </article>
            <article class="portfolioItem">
                <iframe width="100%" height="100%" src="https://codelabenterprise.com/optimize-image/"></iframe>
            </article>
            <article class="portfolioItem">

            </article>
            <article class="portfolioItem">

            </article>
            <article class="portfolioItem">

            </article>
            <article class="portfolioItem">

            </article>
            <article class="portfolioItem">

            </article>
            <article class="portfolioItem">

            </article>
        </section>
    </section>
</template>

<style scoped>

::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.portfolioSection {
    display: flex;
    flex-direction: column;
    padding: 80px;
    gap: 80px;
    background-image: url("@/assets/portfolio.webp");
    background-size: cover;

    & .portfolioHeader {
        display: flex;
        flex-direction: row;
        color: white;
        justify-content: space-between;
        padding: 32px;
        background-color: rgba(var(--primary-rgb), 0.08);
        border-radius: 24px;
        align-items: center;
        overflow: hidden;

        & .main-features-list {
            display: flex;
            flex-direction: row;
            gap: 24px;
            overflow-y: hidden;
            overflow-x: auto;
            border-radius: 20px;
        }
    }

    & .portfolioList {
        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: start; /* Align items to the start of the container */
        overflow-y: hidden;
        overflow-x: auto; /* Enable horizontal scrolling */
        z-index: 2;
        border-radius: 20px;

        & .portfolioItem {
            min-inline-size: 500px; /* Define a minimum width for each item */
            max-block-size: 700px;
            background-color: rgb(106, 101, 109);
            aspect-ratio: 9/16; /* Maintain aspect ratio */
            flex-shrink: 0; /* Prevent items from shrinking */
            border-radius: 20px;
        }
    }
}

@media (width <= 768px) {
    .portfolioSection {
        padding: 32px;
        gap: 40px;

        & .portfolioHeader {
            padding: 16px;
            gap: 16px;

            & .main-features-list {
                display: none;
            }
        }

        & .portfolioList {
            gap: 16px;

            & .portfolioItem {
                min-inline-size: 300px;
                max-block-size: 500px;
            }
        }
    }

}
</style>
